export const productTypes = {
    simple: {
        value: "SIMPLE",
        label: "Simple"
    },
    bundle: {
        value: "BUNDLE",
        label: "Bundle"
    },
    configurable: {
        value: "CONFIGURABLE",
        label: "Configurable"
    }
}

export default {
    enabled: true,
    expectingReturn: false,
    type: '',
    activateLots: '',
    productName: '',
    productShortName: '',
    sku: '',
    skuSupplier: '',
    skuClient: '',
    supplier: '',
    brand: '',
    channel: '',
    productGroup: '',
    productFamily: '',
    productSubFamily: '',
    displayPosition: 0,
    priceSell: '',
    pricePurshase: '',
    productDescription: '',
    images: [],
    documents: [],
    expirationDate: '',
    stockThresholdAlert: 0,
    stockBuffer: 0,
    productCategories: {},
    productLabels: {},
    productTags: [],
    activateLotsEnable: false,
    children: [],
    productAttributes: [],
    prepareDays: 0,
    // helper array
    availableAttributes: []
}