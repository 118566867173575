export const events = [
	{
		name: "Events",
		type: "link",
		icon: "i-Calendar-3",
		path: "/event/list",
		displayPosition: 15,
		key: "EventsEvents"
	}
];

export const catalogs = [
	{
		name: "Catalogs",
		description: "Lorem ipsum dolor sit.",
		type: "dropDown",
		icon: "i-Library",
		box: true,
		displayPosition: 10,
		key: "CatalogCatalogCatalogCategory",
		sub: [
			// {
			// 	icon: "i-Folder",
			// 	name: "Catalog 1",
			// 	path: "/catalog/001",
			// 	type: "dropDown",
			// 	sub: [
			// 		{
			// 			icon: "i-Receipt-4",
			// 			name: "Category 1",
			// 			path: "/catalog/001/category",
			// 		},
			// 		{
			// 			icon: "i-Receipt-4",
			// 			name: "Category 2",
			// 			path: "/catalog/001/category",
			// 		},
			// 		{
			// 			icon: "i-Receipt-4",
			// 			name: "Category 3",
			// 			path: "/catalog/001/category",
			// 		},
			// 		{
			// 			icon: "i-Receipt-4",
			// 			name: "Category 4",
			// 			path: "/catalog/001/category",
			// 		},
			// 	],
			// }
		],
	},
];

export const orders = [
	{
		name: "Orders",
		description: "Lorem ipsum dolor sit.",
		type: "dropDown",
		icon: "i-File-Clipboard-File--Text",
		box: true,
		displayPosition: 20,
		// path: "/orders/list",
		key: "OrdersMenu",
		sub: [
			{
				icon: "i-Download1",
				name: "Inbound_Orders",
				type: "link",
				path: "/orders/inbound/list",
				key: "BoxOrdersInboundOrders"
			},
			{
				icon: "i-Upload1",
				name: "Outbound_orders",
				type: "link",
				path: "/orders/outbound/list",
				key: "BoxOrdersOutboundOrders"
			},
		],
	},
];

export const boxManagement = [
	{
		name: "Box Management",
		description: "Lorem ipsum dolor sit.",
		type: "dropDown",
		icon: "i-Management",
		box: true,
		displayPosition: 30,
		key: "BoxManagementMenu",
		sub: [
			{
				icon: "i-Inbox-Into",
				name: "Box Inventory",
				type: "link",
				path: "/box-management/box-inventory/list",
				key: "BoxBoxManagementBoxInventory"
			},
			{
				icon: "i-Sync",
				name: "Stock_Regulation",
				type: "link",
				path: "/box-management/stock-regulation/list",
				key: "BoxBoxManagementBoxRegulation",
			},
			{
				icon: "i-Camera",
				name: "Box Images",
				type: "link",
				path: "/box-management/box-image/list",
				key: "BoxBoxManagementBoxImages"
			},
		],
	},
];

export const quotas = [
	{
		name: "Quotas",
		type: "link",
		icon: "MdOutlineChecklist",
		path: "/quotas/list",
		displayPosition: 35,
		key: "QuotasQuotas"
	}
];

export const admin = [
	{
		name: "Admin",
		description: "Lorem ipsum dolor sit.",
		type: "dropDown",
		icon: "i-Lock-2",
		displayPosition: 40,
		key: "AdminMenu",
		sub: [
			{
				icon: "i-File-Clipboard-Text--Image",
				name: "Client administration",
				type: "dropDown",
				key: "AdminClientAdministrationMenu",
				sub: [
					{
						icon: "i-Financial",
						name: "Clients",
						path: "/admin/client/list",
						type: "link",
						key: "CoreAdminClientAdministrationClients"
					},
					{
						icon: "i-Inbox-Empty",
						name: "Accounts",
						path: "/admin/account/list",
						type: "link",
						key: "CoreAdminClientAdministrationAccounts"
					},
					{
						icon: "i-Male",
						name: "Users",
						path: "/admin/user/list",
						type: "link",
						key: "CoreAdminClientAdministrationUser"
					},
					{
						icon: "RiShieldUserLine",
						name: "Roles",
						path: "/admin/role/list",
						type: "link",
						key: "CoreAdminClientAdministrationRoles"
					},
					{
						icon: "BsTruck",
						name: "Shipping Methods",
						path: "/admin/shipping-method/list",
						type: "link",
						key: "CoreAdminClientAdministrationShippingMethods"
					},
					{
						icon: "IoPieChartOutline",
						name: "Custom Reports",
						path: "/admin/custom-reports/list",
						type: "link",
						key: "CoreAdminClientAdministrationCustomReports"
					}
				],
			},
			{
				icon: "i-File-Clipboard-Text--Image",
				name: "Client Manager",
				type: "dropDown",
				key: "AdminClientManagerMenu",
				sub: [
					{
						icon: "FaStreetView",
						name: "Hierarchy",
						path: "/admin/hierarchy/list",
						type: "link",
						key: "CoreAdminClientManagerManageHierarchy"
					},
					{
						icon: "i-Conference",
						name: "Groups",
						path: "/admin/groups/list",
						type: "link",
						key: "CoreAdminClientManagerGroups"
					},
					{
						icon: "i-Address-Book",
						name: "Address Book",
						path: "/admin/address-book/list",
						type: "link",
						key: "CoreAdminClientManagerAddressBook"
					},
				]
			},
			{
				icon: "i-File-Clipboard-Text--Image",
				name: "Catalog administration",
				type: "dropDown",
				key: "AdminCatalogAdministrationMenu",
				sub: [
					{
						icon: "i-Book",
						name: "Catalogues / Category",
						path: "/admin/catalogs",
						type: "link",
						key: "CatalogAdminCatalogAdministrationCatalog"
					},
					{
						icon: "IoOptions",
						name: "Product Attributes",
						path: "/admin/product/attributes/list",
						type: "link",
						key: "CatalogAdminCatalogAdministrationProductAttributes"
					},
					{
						icon: "i-Shopping-Bag",
						name: "Products",
						path: "/admin/products/list",
						type: "link",
						key: "CatalogAdminCatalogAdministrationProducts"
					},
					{
						icon: "MdOutlineChecklist",
						name: "Quotas",
						path: "/admin/quota/list",
						type: "link",
						key: "QuotasAdminCatalogAdministrationQuotas"
					},
				],
			},
			{
				icon: "i-File-Clipboard-Text--Image",
				name: "Box administration",
				type: "dropDown",
				key: "AdminBoxAdministrationMenu",
				sub: [
					{
						icon: "i-Empty-Box",
						name: "Box_Administration",
						path: "/admin/box-administration",
						type: "link",
						key: "BoxAdminBoxAdministrationBoxAdministration"
					},
					{
						icon: "i-Receipt-4",
						name: "Provider Sites",
						path: "/admin/provider-sites",
						type: "link",
						key: "BoxAdminBoxAdministrationProviderSite",
					},
					{
						icon: "i-Paper-Plane",
						name: "Provider",
						path: "/admin/provider",
						type: "link",
						key: "BoxAdminBoxAdministrationProvider",
					},
				],
			},
		],
	},
];

export const reports = [
	{
		name: "Reports",
		description: "Lorem ipsum dolor sit.",
		type: "dropDown",
		icon: "IoPieChartOutline",
		displayPosition: 45,
		key: "ReportsMenu",
		sub: [
			{
				icon: "AiOutlineAreaChart",
				name: "Warehouse Reports",
				type: "dropDown",
				key: "ReportsWarehouseReportsMenu",
				sub: [
					{
						icon: "i-Empty-Box",
						name: "Warehouse Stock",
						path: "/reports/warehouse/stock",
						type: "link",
						key: "ReportsReportsWarehouseReportsStock"
					},
					{
						icon: "i-Empty-Box",
						name: "Warehouse Stock Details",
						path: "/reports/warehouse/stock-details",
						type: "link",
						key: "ReportsReportsWarehouseReportsStockDetails"
					},
					{
						icon: "i-Upload1",
						name: "Outbound Orders",
						path: "/reports/warehouse/outbound-orders",
						type: "link",
						key: "ReportsReportsWarehouseReportsOutboundOrders"
					},
					{
						icon: "i-Upload1",
						name: "Outbound Orders Details",
						path: "/reports/warehouse/outbound-orders-details",
						type: "link",
						key: "ReportsReportsWarehouseReportsOutboundOrdersDetails"
					},
					// {
					// 	icon: "i-Download1",
					// 	name: "Inbound Orders",
					// 	path: "/reports/warehouse/inbound-orders",
					// 	type: "link"
					// },
				]
			},
			{
				icon: "AiOutlineAreaChart",
				name: "Box Reports",
				type: "dropDown",
				key: "ReportsBoxReportsMenu",
				sub: [
					{
						icon: "i-Empty-Box",
						name: "Box Stock",
						path: "/reports/box/stock",
						type: "link",
						key: "BoxReportsBoxReportsStock"
					},
					{
						icon: "i-Empty-Box",
						name: "Box Stock Details",
						path: "/reports/box/stock-details",
						type: "link",
						key: "BoxReportsBoxReportsStockDetails"
					},
					{
						icon: "i-Download1",
						name: "Inbound Orders",
						path: "/reports/box/inbound-orders",
						type: "link",
						key: "BoxReportsBoxReportsInboundOrders"
					},
					{
						icon: "i-Download1",
						name: "Inbound Orders Details",
						path: "/reports/box/inbound-orders-details",
						type: "link",
						key: "BoxReportsBoxReportsInboundOrdersDetails"
					},
					{
						icon: "i-Upload1",
						name: "Outbound Orders",
						path: "/reports/box/outbound-orders",
						type: "link",
						key: "BoxReportsBoxReportsOutboundOrders"
					},
					{
						icon: "i-Upload1",
						name: "Outbound Orders Details",
						path: "/reports/box/outbound-orders-details",
						type: "link",
						key: "BoxReportsBoxReportsOutboundOrdersDetails"
					}
				]
			},
		],
	}
]

export const navigations = [
	{
		name: "Dashboard",
		description: "Lorem ipsum dolor sit.",
		type: "link",
		icon: "i-Bar-Chart",
		path: "/dashboard",
		displayPosition: 0,
		key: "CoreDashboard",
	},
	// ...catalogs,
	// ...orders,
	// ...boxManagement,
	// ...quotas
	// ...admin,

	{
		name: "Get support",
		description: "Lorem ipsum dolor sit.",
		type: "extLink",
		icon: "i-Consulting",
		path: "/zendesk/sso/login",
		target: "_blank",
		displayPosition: 50
	},
];
