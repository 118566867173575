import { NotificationManager } from "react-notifications";
import swal from "sweetalert2";
import i18n from "i18next";

export const Notifications = (error, message, type, timeOut, onClick, priority) => {
    // DO it like this because of the old logic written by Nikolay
    if (typeof error === 'object' && !Array.isArray(error)) {
        return NotificationManager.error(error.message, i18n.t('Error'), timeOut, onClick, priority);
    }

    switch (type) {
        case "info":
            return NotificationManager.info(message, i18n.t('Info'), timeOut, onClick, priority);
        case "warning":
            return NotificationManager.warning(message, i18n.t('Warning'), timeOut, onClick, priority);
        case "error":
            return NotificationManager.error(message, i18n.t('Error'), timeOut, onClick, priority);
        case "success":
        default:
            return NotificationManager.success(message, i18n.t('Success'), timeOut, onClick, priority);
    }
}

export const ConfirmNotification = (title, description, confirmButtonText) => {
    return swal
        .fire({
            title: title,
            text: description,
            icon: "warning",
            type: "question",
            showCancelButton: true,
            confirmButtonColor: "#28a745",
            cancelButtonColor: "#d33",
            confirmButtonText: confirmButtonText,
            cancelButtonText: i18n.t("No"),
            allowOutsideClick: false
        })
}

export const SessionTimeoutNotification = (timer = 60000) => {

    let timerInterval
    return swal.fire({
        title: i18n.t('Session Timeout'),
        icon: "warning",
        showCancelButton: true,
        cancelButtonColor: "#d33",
        cancelButtonText: i18n.t("Sign out"),
        showConfirmButton: true,
        confirmButtonColor: "#28a745",
        confirmButtonText: i18n.t("Stay Logged in") + ' (<strong id="swal-seconds"></strong>)',
        allowOutsideClick: false,
        text: i18n.t("You're being timed out due to inactivity. Please choose to stay signed in or to sign out. Otherwise, you will be signed out automatically."),
        timer,
        onBeforeOpen: () => {
            const content = swal.getActions()
            const $ = content.querySelector.bind(content)
            const seconds = $('#swal-seconds');

            timerInterval = setInterval(() => {
                seconds
                    .textContent = (swal.getTimerLeft() / 1000)
                    .toFixed(0)
            }, 100)
        },
        onClose: () => {
            clearInterval(timerInterval)
        },
    })
}