import React, {useEffect, useState} from "react";
import { NavLink } from "react-router-dom";
import {
	admin,
	boxManagement,
	catalogs as navCatalogs,
	events,
	navigations,
	orders,
	quotas,
	reports
} from "../../navigations";
import {uniqBy} from "lodash";
import { classList, isMobile } from "@utils";
import ScrollBar from "react-perfect-scrollbar";
import { DropDownMenu } from "@gull";
import { useDispatch, useSelector } from "react-redux";
import { setLayoutSettings } from "app/redux/slices/layoutSlice";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import CAN from "../../casl/Can";
import {useTranslation} from "react-i18next";
import SidenavIcons from "../../../@gull/components/SidenavIcons";
import {getAbilitiesForAccount} from "../../casl/Ability";
import {selectUserEventAccount, selectUserIdClientAccount} from "../../redux/slices/userSlice";

const NavItemSkeleton = () => (
	<SkeletonTheme borderRadius="3px">
		<div className="nav-item-hold">
			<Skeleton width={30} height={30} className="mb-1"/>
			<Skeleton count={1} width={80} />
		</div>
	</SkeletonTheme>
);

const Layout1Sidenav = () => {
	const [selectedItem, setSelectedItem] = useState(null);
	const [isLoading, setIsLoading] = useState(true);
	const dispatch = useDispatch();
	const settings = useSelector((state) => state.layout.settings);
	const adminCustomizer = useSelector((state) => state.adminCustomizer);
	const catalogs = useSelector((state) => state.ecommerce.catalogList);
	const activeWarehouse = useSelector(state => state.warehouse.active);
	const user = useSelector(state => state.user);
	const idClientAccount = useSelector(selectUserIdClientAccount);
	const eventAccount = useSelector(selectUserEventAccount);
	const customReports = useSelector(state => state.customReports.list);
	const {t} = useTranslation();

	const accountAbilities = getAbilitiesForAccount(user.accountPermissions || [], idClientAccount, 'View');

	const onMainItemMouseEnter = (item) => {
		if (item.type === "dropDown") {
			setSelected(item);
			openSecSidenav();
		} else {
			setSelected(item);
			closeSecSidenav();
		}
	};

	const onMainItemMouseLeave = () => {
		// closeSecSidenav();
	};

	const setSelected = (selectedItem) => {
		setSelectedItem(selectedItem);
	};

	const openSecSidenav = () => {
		dispatch(
			setLayoutSettings({
				layout1Settings: {
					leftSidebar: {
						secondaryNavOpen: true,
					},
				},
			}),
		);
	};

	const closeSecSidenav = () => {
		let other = {};

		if (isMobile()) {
			other.open = false;
		}

		dispatch(
			setLayoutSettings({
				layout1Settings: {
					leftSidebar: {
						...other,
						secondaryNavOpen: false,
					},
				},
			}),
		);
	};

	const closeSidenav = () => {
		dispatch(
			setLayoutSettings({
				layout1Settings: {
					leftSidebar: {
						open: false,
						secondaryNavOpen: false,
					},
				},
			}),
		);
	};

	const openSidenav = () => {
		dispatch(
			setLayoutSettings({
				layout1Settings: {
					leftSidebar: {
						open: true,
					},
				},
			}),
		);
	};

	useEffect(() => {
		if (selectedItem === null) closeSecSidenav();

		if (window) {
			if (window.innerWidth < 1200) {
				closeSidenav();
			} else {
				openSidenav();
			}
		}

		const listener = () => {
			if (window.innerWidth < 1200) {
				closeSidenav();
			} else {
				openSidenav();
			}
		}

		window.addEventListener("resize", listener, false);

		return () => {
			window.removeEventListener("resize", listener, false);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (adminCustomizer) {
			setIsLoading(adminCustomizer.loading);
		}
	}, [adminCustomizer]);

	const sideNav = [...navigations, ...admin];

	if (eventAccount && idClientAccount) {
		sideNav.push(...events);
	}

	// Load catalogs and categories
	if (catalogs.length) {
		navCatalogs[0].sub = catalogs.map((catalog) => {
			const data = {
				icon: "i-Folder",
				name: catalog.catalogName,
				type: "dropDown",
			};

			if (catalog.categories) {
				data.sub = catalog.categories.map((cat) => {
					const catData = {
						icon: "i-Receipt-4",
						name: cat.categoryName,
						path: `/catalog/${catalog.idCatalog}/category/${cat.idCatalogCategory}`,
					};

					if (cat.childrenCategories && cat.childrenCategories.length > 0) {
						catData.sub = cat.childrenCategories.map((scat) => {
							return {
								icon: "i-Receipt-4",
								name: scat.categoryName,
								path: `/catalog/${catalog.idCatalog}/category/${scat.idCatalogCategory}`,
							};
						});
					}

					return catData;
				});
			}

			return data;
		});
	} else {
		navCatalogs[0].sub = [];
	}

	if (activeWarehouse?.warehouseType === 'WAREHOUSE') {
		orders[0].type = 'link';
		orders[0].path = "/orders/list";
	} else {
		orders[0].type = 'dropDown';
		delete orders[0].path;
	}

	if (customReports.length) {
		const customReportsSub = {
			icon: "AiOutlineAreaChart",
			name: "Custom Reports",
			type: "dropDown",
			key: "ReportsReportsCustomReports",
			sub: customReports
				.filter(customReport => customReport.enabled)
				.sort((a, b) => a.displayPosition - b.displayPosition)
				.map(customReport => {
					return {
						icon: "IoPieChartOutline",
						name: customReport.name,
						path: `/reports/custom-report/${customReport.idCustomReport}`,
						type: "link",
						key: "ReportsReportsCustomReports",
						displayPosition: customReport.displayPosition
					}
				})
		};

		reports[0].sub.unshift(customReportsSub);
		reports[0].sub = uniqBy(reports[0].sub, 'name');
	} else {
		reports[0].sub = reports[0].sub.filter((item) => item.name !== "Custom Reports");
	}

	if (activeWarehouse) {
		sideNav.push(...navCatalogs);
		if (
			(activeWarehouse.warehouseType === "WAREHOUSE" && CAN('View', 'CoreOrders')) ||
			(activeWarehouse.warehouseType === "BOX" && (CAN('View', 'BoxOrdersInboundOrders') || CAN('View', 'BoxOrdersOutboundOrders')))
		) {
			sideNav.push(...orders);
		}
		sideNav.push(...reports);
	}

	if (activeWarehouse?.warehouseType === 'BOX') {
		sideNav.push(...boxManagement);
	}

	if (user.hasQuotas && accountAbilities?.subject.includes("QuotasQuotas")) {
		sideNav.push(...quotas);
	}

	return (
		<div className="side-content-wrap">
			<ScrollBar
				className={classList({
					"sidebar-left o-hidden rtl-ps-none": true,
					open: settings.layout1Settings.leftSidebar.open,
				})}
			>
				<ul className="navigation-left">
					{sideNav
						.sort((a, b) => a.displayPosition - b.displayPosition)
						.map((item, i) => {
							if (isLoading) {
								return (
									<li className="nav-item" key={i}>
										<NavItemSkeleton />
									</li>
								);
							}
							if (CAN("View", item.key) || !item.key) {
								const renderNavLink = (
									<NavLink className="nav-item-hold" to={item.path || "#"}>
										<SidenavIcons icon={item.icon} />
										<span className="nav-text">{t(item.name)}</span>
									</NavLink>
								);

								const renderExternalLink = (
									<a className="nav-item-hold" href={item.path} target={item.target}>
										<SidenavIcons icon={item.icon} />
										<span className="nav-text">{t(item.name)}</span>
									</a>
								);

								const renderPlaceholder = (
									<div className="nav-item-hold">
										<SidenavIcons icon={item.icon} />
										<span className="nav-text">{t(item.name)}</span>
									</div>
								);

								return (
									<li
										className={classList({
											"nav-item": true,
											active: selectedItem === item,
										})}
										onMouseEnter={() => {
											onMainItemMouseEnter(item);
										}}
										onMouseLeave={onMainItemMouseLeave}
										key={i}
									>
										{item.path && item.type !== "extLink" && renderNavLink}
										{item.path && item.type === "extLink" && renderExternalLink}
										{!item.path && renderPlaceholder}
										<div className="triangle" />
									</li>
								);
							}
							return null;
						})}
				</ul>
			</ScrollBar>

			<ScrollBar
				className={classList({
					"sidebar-left-secondary o-hidden rtl-ps-none": true,
					open: settings.layout1Settings.leftSidebar.secondaryNavOpen,
				})}
			>
				{selectedItem && selectedItem.sub && (
					<DropDownMenu menu={selectedItem.sub} closeSecSidenav={closeSecSidenav} />
				)}
				<span />
			</ScrollBar>

			<div
				onMouseEnter={closeSecSidenav}
				className={classList({
					"sidebar-overlay": true,
					open: settings.layout1Settings.leftSidebar.secondaryNavOpen,
				})}
			/>
		</div>
	);
};

export default Layout1Sidenav;
