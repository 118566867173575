import { lazy } from "react";

const InboundOrders = lazy(() =>
    import("./inbound-orders/InboundOrdersList")
);
const OutboundOrders = lazy( () =>
    import('./outbound-orders/OutboundOrdersList')
)
const CustomerOrdersList = lazy( () =>
    import('./customer-orders/CustomerOrdersList')
)
const CustomerOrdersBulkImport = lazy( () =>
    import('./customer-orders/bulk-import/CustomerOrdersBulkImport')
)
const CustomerOrdersBulkImportWizard = lazy( () =>
    import('./customer-orders/bulk-import-wizard/BulkImportWizard')
)

const dataTableRoute = [
    {
        path: "/orders/list",
        component: CustomerOrdersList,
        exact: true,
        key: "View_CoreOrders"
    },
    {
        path: "/orders/bulk-import",
        component: CustomerOrdersBulkImport,
        exact: true,
        key: "BulkImport_CoreOrders"
    },
    {
        path: "/orders/bulk-import-wizard",
        component: CustomerOrdersBulkImportWizard,
        exact: true,
        key: "View_CoreOrdersImportWizard"
    },
    {
        path: "/orders/inbound/list",
        component: InboundOrders,
        key: "View_BoxOrdersInboundOrders"
    },
    {
        path: "/orders/outbound/list",
        component: OutboundOrders,
        key: "View_BoxOrdersOutboundOrders"
    }
];

export default dataTableRoute;
