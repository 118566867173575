import moment from "moment";
import {cloneDeep} from "lodash";
import {fromBuffer} from "file-type/browser";
import * as XLSX from "xlsx";
import * as FileSaver from 'file-saver';

export function debounce(func, wait, immediate) {
  var timeout;
  return function() {
    var context = this,
      args = arguments;
    clearTimeout(timeout);
    timeout = setTimeout(function() {
      timeout = null;
      if (!immediate) func.apply(context, args);
    }, wait);
    if (immediate && !timeout) func.apply(context, args);
  };
}

export function isMobile() {
  if (window) {
    return window.matchMedia(`(max-width: 767px)`).matches;
  }
  return false;
}

export function isMdScreen() {
  if (window) {
    return window.matchMedia(`(max-width: 1199px)`).matches;
  }
  return false;
}

function currentYPosition() {
  if (!window) {
    return;
  }
  // Firefox, Chrome, Opera, Safari
  if (window.pageYOffset) return window.pageYOffset;
  // Internet Explorer 6 - standards mode
  if (document.documentElement && document.documentElement.scrollTop)
    return document.documentElement.scrollTop;
  // Internet Explorer 6, 7 and 8
  if (document.body.scrollTop) return document.body.scrollTop;
  return 0;
}

function elmYPosition(elm) {
  var y = elm.offsetTop;
  var node = elm;
  while (node.offsetParent && node.offsetParent !== document.body) {
    node = node.offsetParent;
    y += node.offsetTop;
  }
  return y;
}

export function scrollTo(scrollableElement, elmID) {
  var elm = document.getElementById(elmID);
  if (!elmID || !elm) {
    return;
  }
  var startY = currentYPosition();
  var stopY = elmYPosition(elm);
  var distance = stopY > startY ? stopY - startY : startY - stopY;
  if (distance < 100) {
    scrollTo(0, stopY);
    return;
  }
  var speed = Math.round(distance / 50);
  if (speed >= 20) speed = 20;
  var step = Math.round(distance / 25);
  var leapY = stopY > startY ? startY + step : startY - step;
  var timer = 0;
  if (stopY > startY) {
    for (var i = startY; i < stopY; i += step) {
      setTimeout(
        (function(leapY) {
          return () => {
            scrollableElement.scrollTo(0, leapY);
          };
        })(leapY),
        timer * speed
      );
      leapY += step;
      if (leapY > stopY) leapY = stopY;
      timer++;
    }
    return;
  }
  for (let i = startY; i > stopY; i -= step) {
    setTimeout(
      (function(leapY) {
        return () => {
          scrollableElement.scrollTo(0, leapY);
        };
      })(leapY),
      timer * speed
    );
    leapY -= step;
    if (leapY < stopY) leapY = stopY;
    timer++;
  }
  return false;
}

export function getTimeDifference(date) {
  let difference =
    moment(new Date(), "DD/MM/YYYY HH:mm:ss").diff(
      moment(date, "DD/MM/YYYY HH:mm:ss")
    ) / 1000;

  if (difference < 60) return `${Math.floor(difference)} seconds`;
  else if (difference < 3600) return `${Math.floor(difference / 60)} minutes`;
  else if (difference < 86400) return `${Math.floor(difference / 3660)} hours`;
  else if (difference < 86400 * 30)
    return `${Math.floor(difference / 86400)} days`;
  else if (difference < 86400 * 30 * 12)
    return `${Math.floor(difference / 86400 / 30)} months`;
  else return `${(difference / 86400 / 30 / 12).toFixed(1)} years`;
}

export function generateRandomId() {
  let tempId = Math.random().toString();
  let uid = tempId.substr(2, tempId.length - 1);
  return uid;
}

export function getQueryParam(prop) {
  var params = {};
  var search = decodeURIComponent(
    window.location.href.slice(window.location.href.indexOf("?") + 1)
  );
  var definitions = search.split("&");
  definitions.forEach(function(val, key) {
    var parts = val.split("=", 2);
    params[parts[0]] = parts[1];
  });
  return prop && prop in params ? params[prop] : params;
}

/**
 * Updates the query parameters of a URL with the values provided in an object.
 * @param {object} queryParams - An object containing the new query parameters.
 * @param {string} url - The URL to update (default: current page URL).
 * @returns {URL} The updated URL object.
 */
export function updateQueryParams(queryParams = {}, url = window.location.href) {
  const urlObj = new URL(url);
  const params = urlObj.searchParams;

  // Delete any parameters that are in the URL but not in the queryParams object
  for (const key of params.keys()) {
    if (!queryParams.hasOwnProperty(key)) {
      params.delete(key);
    }
  }

  // Set or update parameters from the queryParams object
  for (const [key, value] of Object.entries(queryParams)) {
    if (value === null || value === undefined) {
      params.delete(key);
    } else {
      params.set(key, typeof value !== "string" ? JSON.stringify(value) : value);
    }
  }

  // Sort the parameters by the order of their keys in the queryParams object
  const paramsSortOrder = Object.keys(queryParams);

  const sortedParams = paramsSortOrder.reduce((acc, key) => {
    if (params.has(key)) {
      acc.append(key, params.get(key));
    }
    return acc;
  }, new URLSearchParams());

  urlObj.search = sortedParams.toString();
  return urlObj;
}

export function classList(classes) {
  return Object.entries(classes)
    .filter(entry => entry[1])
    .map(entry => entry[0])
    .join(" ");
}

export function initCodeViewer() {
  if(!document) return;
  const pre = document.getElementsByTagName('pre');
  if(!pre.length) return;
  Array.prototype.map.call(pre, p => {
    // console.log(p);
    p.classList.add('collapsed');
    p.addEventListener('click', (e) => {
      console.log(e.target);
      e.target.classList.remove('collapsed');
    })
  });

  // pre.map(p => {
  //   console.log(p)
  // })

}

export function formatDate(dateString) {
  if (!dateString) return "";
  let myDate = new Date(dateString);
  return ('0' + myDate.getDate()).slice(-2) + '/' + ('0' + (myDate.getMonth()+1)).slice(-2) + '/' + myDate.getFullYear();
}

export function isValidDate(dateString) {
  return !isNaN(Date.parse(dateString));
}

export function dateToSqlDatetime(dateString) {
  if (!isValidDate(dateString)) return null;
  return moment(dateString).toISOString().slice(0, 19);
}

/**
 * Adds a specified number of weekdays to a given date while excluding weekends if specified.
 *
 * @param {Date} date - The date to add weekdays to.
 * @param {number} days - The number of weekdays to add.
 * @param {boolean} addWeekend - If true, Saturday and Sunday are treated as weekdays and included in the calculation; otherwise, they are excluded.
 *
 * @returns {Date} - The resulting date after adding the specified number of weekdays.
 */
export function addWeekdays(date, days, addWeekend) {
  date = moment(date); // clone
  while (days > 0) {
    date = date.add(1, 'days');
    // decrease "days" only if it's a weekday.
    if (!addWeekend && date.isoWeekday() !== 6 && date.isoWeekday() !== 7) {
      days -= 1;
    } else if (addWeekend) {
      days -= 1;
    }
  }
  return date;
}

/**
 * Determines if a given date is a weekday (Monday to Friday).
 *
 * @param {Date} date - The date to check.
 *
 * @returns {boolean} - True if the date is a weekday (Monday to Friday); otherwise, false.
 */
export function isWeekday(date) {
  date = moment(date)
  return date.isoWeekday() !== 6 && date.isoWeekday() !== 7;
}

// even
// console.log(getEvenOdd(3, 10).join(' ')); // [4, 6, 8, 10]
// odd
// console.log(getEvenOdd(10, 3).join(' ')); // [9, 7, 5, 3]
export const getEvenOdd = (a, b) => {
  var inc = +(a < b) || -1,
      i,
      result = [];
  for (i = a; i !== b + inc; i += inc) {
    if (+(inc === 1) !== i % 2) {
      result.push(i);
    }
  }
  return result;
}

export const getDaysBetweenDates = (start, end, day, increment) => {
  const current = moment(start);
  if (day && current.day(day).isBefore(moment(start))) {
    // this fix missing day of current week
    current.add(1, 'week');
  }

  const endDate = moment(end);
  const weeksIncrement = increment ? parseInt(increment) : 1;

  const startOfISOWeek = moment(current).startOf('isoWeek')
  const currentWeek = startOfISOWeek.month() === 0 ? startOfISOWeek.week() : startOfISOWeek.isoWeek();

  if (day && current.day(day).isAfter(moment(start)) && currentWeek % 2 !== 0 && weeksIncrement === 5) {
    //  this fix date of current week if not EVEN week
    current.add(1, 'week');
  }

  if (day && current.day(day).isAfter(moment(start)) && currentWeek % 2 === 0 && weeksIncrement === 6) {
    // this fix date of current week if not ODD week
    current.add(1, 'week');
  }

  const daysOfWeek = [];

  while (current <= endDate) {
    const weekStart = current.clone().startOf('isoWeek');

    let weeksToAdd = 1;
    let flag = false; // flag is responsible when to add days to daysOfWeek array

    if (weeksIncrement === 5 || weeksIncrement === 6) {
      const currentWeek = weekStart.month() === 0 ? weekStart.week() : weekStart.isoWeek();

      //check if the number is even
      if (currentWeek % 2 === 0 && weeksIncrement === 5) {
        weeksToAdd = 2;
        flag = true;
      }

      if (currentWeek % 2 !== 0 && weeksIncrement === 6) {
        weeksToAdd = 2;
        flag = true;
      }
    } else {
      weeksToAdd = weeksIncrement;
      flag = true;
    }

    if (day && flag) {
      daysOfWeek.push(current.day(day).toDate());
    }
    // if not day is selected add all days in week
    if (!day && flag) {
      for (let i = 0; i <= 6; i++) {
        daysOfWeek.push(moment(weekStart).add(i, 'days').toDate());
      }
    }

    current.add(weeksToAdd, "weeks");
  }

  return daysOfWeek;
}

export function sleep(ms) {
  return new Promise((r) => setTimeout(r, ms));
}

export function getFileType(fileName) {
  if(!fileName) {
    return '-'
  }

  fileName = fileName.toLowerCase();
  const fileExtension = fileName.split('.').pop()
  const findTerm = (term) => {
    if (fileExtension.includes(term)){
      return fileExtension;
    }
  };

  let fileType = '';
  switch (fileExtension) {
    case findTerm('tif'):
      fileType = 'tif'
      break;
    case findTerm('bmp'):
      fileType = 'bmp'
      break;
    case findTerm('jpeg'):
      fileType = 'jpeg'
      break;
    case findTerm('jpg'):
      fileType = 'jpg'
      break;
    case findTerm('gif'):
      fileType = 'gif'
      break;
    case findTerm('png'):
      fileType = 'png'
      break;
    case findTerm('jfif'):
      fileType = 'jpg'
      break;
    case findTerm('eps'):
      fileType = 'eps'
      break;
    case findTerm('rar'):
      fileType = 'rar'
      break;
    case findTerm('zip'):
      fileType = 'zip'
      break;
    case findTerm('doc'):
      fileType = 'msword'
      break;
    case findTerm('docx'):
      fileType = 'vnd.openxmlformats-officedocument.wordprocessingml.document'
      break;
    case findTerm('xls'):
      fileType = 'vnd.ms-excel'
      break;
    case findTerm('xlsx'):
      fileType = 'vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      break;
    case findTerm('ppt'):
      fileType = 'vnd.ms-powerpoint'
      break;
    case findTerm('pptx'):
      fileType = 'vnd.openxmlformats-officedocument.presentationml.presentation'
      break;
    case findTerm('mdb'):
      fileType = 'vnd.ms-access'
      break;
    case findTerm('pdf'):
      fileType = 'pdf'
      break;
    default:
      fileType = 'msword'
      break;
  }

  return fileType;
}

export function getFileExtension(fileType) {
  if(!fileType) {
    return '-'
  }

  const findTerm = (term) => {
    if (fileType.includes(term)){
      return fileType;
    }
  };

  let fileExtensions = '';
  switch (fileType) {
    case findTerm('tif'):
      fileExtensions = 'tif'
      break;
    case findTerm('bmp'):
      fileExtensions = 'bmp'
      break;
    case findTerm('jp'):
      fileExtensions = 'jpeg'
      break;
    case findTerm('gif'):
      fileExtensions = 'gif'
      break;
    case findTerm('png'):
      fileExtensions = 'png'
      break;
    case findTerm('eps'):
      fileExtensions = 'eps'
      break;
    case findTerm('rar'):
      fileExtensions = 'rar'
      break;
    case findTerm('zip'):
      fileExtensions = 'zip'
      break;
    case findTerm('msword'):
      fileExtensions = 'doc'
      break;
    case findTerm('vnd.openxmlformats-officedocument.wordprocessingml.document'):
      fileExtensions = 'docx'
      break;
    case findTerm('vnd.ms-excel'):
      fileExtensions = 'xls'
      break;
    case findTerm('vnd.openxmlformats-officedocument.spreadsheetml.sheet'):
    case findTerm('application/vnd.open'):
      fileExtensions = 'xlsx'
      break;
    case findTerm('vnd.ms-powerpoint'):
      fileExtensions = 'ppt'
      break;
    case findTerm('vnd.openxmlformats-officedocument.presentationml.presentation'):
      fileExtensions = 'pptx'
      break;
    case findTerm('vnd.ms-access'):
      fileExtensions = 'mdb'
      break;
    case findTerm('pdf'):
      fileExtensions = 'pdf'
      break;
    default:
      fileExtensions = 'doc'
      break;
  }

  return fileExtensions;
}

/**
 * Replaces placeholders in a template with corresponding values from the variables object.
 * Double curly braces replacer {{name}} or {{person.name}}
 *
 * @param {Object} template - The template string or object containing placeholders.
 * @param {Object} variables - The object containing the values to replace the placeholders.
 * @returns {Object} - The modified template object with placeholders replaced by values.
 */
export const replacePlaceholders = (template, variables) => {
  // Regular expression to match placeholders in the template
  const regex = /{{([^{}]+)}}/g;

  // Deep clone the template object and replace placeholders
  return JSON.parse(JSON.stringify(template), (key, value) => {
    if (typeof value === 'string') {
      return value.replace(regex, (_, g) => {
        const keys = g.split('.');
        let replacement = variables;
        for (const key of keys) {
          replacement = replacement[key];
          if (replacement === undefined) return '';
        }
        return replacement;
      });
    }
    return value;
  });
}

// export function replacePlaceholders(template, variables) {
//   const regex = /{{([^{}]+)}}/g;
//
//   const clonedTemplate = cloneDeep(template);
//
//   function replaceValue(value) {
//     if (typeof value === 'string') {
//       return value.replace(regex, (_, g) => {
//         const keys = g.split('.');
//         let replacement = variables;
//         for (const key of keys) {
//           replacement = replacement[key];
//           if (replacement === undefined) return '';
//         }
//         return replacement;
//       });
//     }
//     return value;
//   }
//
//   function replaceRecursive(obj) {
//     if (Array.isArray(obj)) {
//       return obj.map(replaceRecursive);
//     } else if (obj !== null && typeof obj === 'object') {
//       return mapValues(obj, replaceRecursive);
//     } else {
//       return replaceValue(obj);
//     }
//   }
//
//   return replaceRecursive(clonedTemplate);
// }

/**
 *
 * @type {function(*, *): any}
 *
 * double curly braces replacer
 * @see https://github.com/tarangkhandelwal/substitutor.js
 */
export const replacer = (function () {
  const regEx = /{{([^{]*?)}}/g;

  const checkForSubstitutors = function (str) {
    return regEx.test(str);
  };

  const getSubstitueValue = function (context) {
    return function (regexMatch, placeholder) {
      const splitArray = placeholder.split(".");
      let currentContext = context;
      while (splitArray.length) {
        const item = splitArray.shift();
        if (typeof currentContext === "object" && item in currentContext)
          currentContext = currentContext[item];
        else return null;
      }
      return currentContext;
    };
  };

  return function (input, context) {
    const inputType = typeof input;

    if (inputType === "object") input = JSON.stringify(input);

    while (checkForSubstitutors(input)) {
      input = input.replace(regEx, getSubstitueValue(context));
    }

    return inputType === "object" ? JSON.parse(input) : input;
  };
})();

/**
 * @see https://stackoverflow.com/questions/58908893/flatten-array-of-objects-with-nested-children
 * @param items
 * @returns {*}
 */
export const deepFlattenChildren = (items) => {
  // const flatten = JSON.parse(JSON.stringify(items))// Important - create a deep copy of 'items' / preferably use lodash '_.cloneDeep(items)', but for the example this will do
  const flatten = cloneDeep(items);
  for (let i = 0; i < flatten.length; i++) {
    if (flatten[i].hasOwnProperty('children')) {
      flatten.splice(i + 1, 0, ...flatten[i].children)
      delete flatten[i].children
    }
  }

  return flatten;
}

// export const assignDepthChildren = (arr, depth = 1, index = 0) => {
//   const result = [...arr];
//   if(result[index]){
//     result[index].depth = depth;
//     if(result[index].children && result[index].children.length){
//       for (let i = 0; i < result[index].children.length ; i++) {
//         assignDepthChildren(result[index].children, depth + 1, i);
//       }
//     }
//     assignDepthChildren(result, depth, index + 1);
//   }
//   return result;
// };

/**
 * Takes an array of objects and assigns a "depth" property to each item based on its nesting level.
 * This is ChatGPT 3.5 Turbo refactored version
 *
 * @param {Array} arr - The array of objects to be modified.
 * @param {Number} [depth=1] - The initial depth level (defaults to 1).
 * @returns {Array} A new array of objects with the "depth" property assigned to each item.
 */
export const assignDepthChildren = (arr, depth = 1) => {
  return arr.map(item => {
    if (item.children && item.children.length) {
      return {
        ...item,
        depth,
        children: assignDepthChildren(item.children, depth + 1)
      }
    }
    return {
      ...item,
      depth
    }
  });
};

/**
 *
 * @param {Array} arr Array of data *
 * @param {String} separator Level separator, defaults to "." *
 * @param {String} level Initial level *
 * @param {Integer} index start child index *
 * @returns {Array}
 * */

export const assignChildrenLevel = (arr, separator = ".", level = "1", index = 0) => {
  if (!Array.isArray(arr)) {
    // Return empty array if arr is not an array
    return [];
  }
  const result = arr.map((item) => ({ ...item }));
  if (arr[index]) {
    result[index] = { ...result[index], level: `${level}` };
    if (result[index].children && result[index].children.length) {
      for (let i = 0; i < result[index].children.length ; i++) {
        result[index].children = assignChildrenLevel(result[index].children, separator, `${level}${separator}${i + 1}`, i);
      }
    }
  }
  return result;
};

export const getFileTypeFromBase64DataString = async (data) => {
  const buffer = Buffer.from(data, 'base64');
  return await fromBuffer(buffer);
}

export const getFileTypeFromUrl = async (url) => {
  const buffer = await fetch(url)
      .then((response) => response.arrayBuffer())
      .catch((error) => console.error(error))
  return await fromBuffer(buffer);
}

//return a promise that resolves with a File instance
export const urlToFile = (url, filename, mimeType) => {
  return (fetch(url)
          .then(function(res){ return res.arrayBuffer(); })
          .then(function(buf){ return new File([buf], filename,{type:mimeType}); })
          .catch((error) => console.error(error))
  );
}

/**
 * Format bytes as human-readable text.
 *
 * @param bytes Number of bytes.
 * @param si True to use metric (SI) units, aka powers of 1000. False to use
 *           binary (IEC), aka powers of 1024.
 * @param dp Number of decimal places to display.
 *
 * @return Formatted string.
 */
export const humanFileSize = (bytes, si= false, dp= 1) => {
  const thresh = si ? 1000 : 1024;

  if (Math.abs(bytes) < thresh) {
    return bytes + ' B';
  }

  const units = si
      ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
      : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
  let u = -1;
  const r = 10**dp;

  do {
    bytes /= thresh;
    ++u;
  } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);


  return bytes.toFixed(dp) + ' ' + units[u];
}

export const fileToBase64 = (file) => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result);
  reader.onerror = error => reject(error);
});


export const PRODUCT_FILE_TYPE_IMG = 'productImage';
export const PRODUCT_FILE_TYPE_DOC = 'productDocument';

export const buildFileObjectFromFileInfo = async (fileInfo) => {
  const isUrl = isValidUrl(fileInfo.dataString);
  const productFileType = fileInfo.hasOwnProperty('idImage') ? PRODUCT_FILE_TYPE_IMG : (fileInfo.hasOwnProperty('idDocument') ? PRODUCT_FILE_TYPE_DOC : '');

  let fileType;
  let base64;
  if (!isUrl) {
    fileType = fileInfo.mime && fileInfo.ext ? {mime: fileInfo.mime, ext: fileInfo.ext} : await getFileTypeFromBase64DataString(fileInfo.dataString);
    base64 =  fileInfo.base64 || `data:${fileType.mime};base64,${fileInfo.dataString}`;
  } else {
    fileType = await getFileTypeFromUrl(fileInfo.dataString);
    base64 =  fileInfo.dataString
  }

  const file = await urlToFile(base64, fileInfo.imageName || fileInfo.documentName, fileType.mime);

  const result = {
    base64,
    defaultFile: false,
    displayPosition: fileInfo.displayPosition,
    ext: fileType.ext,
    file,
    fileKey: fileInfo.fileKey || '',
    fileTitle: fileInfo.fileTitle || '',
    height: 0,
    mime: fileType.mime,
    name: fileInfo.name || '',
    size: file.size,
    width: 0,
    isNew: false
  };

  switch (productFileType) {
    case PRODUCT_FILE_TYPE_IMG:
      const img = new Image();
      img.src = base64;
      img.onload = (ev) => {
        result.height = ev.target.height;
        result.width = ev.target.width;
      }

      result.defaultFile = fileInfo.defaultImage;
      result.fileKey = fileInfo.idImage;
      result.fileTitle = fileInfo.imageName;
      result.name = fileInfo.imageFileName;

      break;
    case PRODUCT_FILE_TYPE_DOC:
      result.fileKey = fileInfo.idDocument;
      result.fileTitle = fileInfo.documentName;
      result.name = fileInfo.documentFileName;

      break;
    default:
  }

  return result;
}

export const escapeSpecialChars = (str) => {
  if (typeof(str) !== "string") return str;
  return str
      // eslint-disable-next-line no-useless-escape
      .replace(/[\"]/g, '\\"')
      .replace(/[\\]/g, '\\\\')
      // eslint-disable-next-line no-useless-escape
      .replace(/[\/]/g, '\\/')
      .replace(/[\b]/g, '\\b')
      .replace(/[\f]/g, '\\f')
      .replace(/[\n]/g, '\\n')
      .replace(/[\r]/g, '\\r')
      .replace(/[\t]/g, '\\t')
      .replace(/\\'/g, "\\'")
};

export const replaceSpecialChars = (str) => {
  if (typeof(str) !== "string") return str;

  return str
      .replace(/\\"/g, '&quot;')
      .replace(/\\\\/g, '&#92;')
      .replace(/\\\//g, '&#47;')
      .replace(/\\b/g, '&#8;')
      .replace(/\\f/g, '&#12;')
      .replace(/\\n/g, '<br>')
      .replace(/\\r/g, '<br>')
      .replace(/\\t/g, '&#9;')
      .replace(/\\'/g, '&#39;')
      .replace(/\\/g, '');
}

export const removeNonPrintableCharacters = (input) => {
  return input.replace(/[\u0000-\u001F\u007F-\u009F]/g, '');
}

export const exportJsonToXlsx = (jsonData, fileName) => {
  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';

  const ws = XLSX.utils.json_to_sheet(jsonData);
  const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
  const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
  const data = new Blob([excelBuffer], {type: fileType});
  FileSaver.saveAs(data, fileName + fileExtension);
}

/**
 * Take an array of objects of similar structure and convert it to a CSV.
 * @source     https://gist.github.com/sators/2c2578018c1aa1f7fedececc39658f17
 * @param      {Array}  jsonData        Array of data
 * @param      {String} fileName        CSV File name
 * @param      {String} columnDelimiter Column separator, defaults to ","
 * @param      {String} lineDelimiter   Line break, defaults to "\n"
 * @return     {String}                 CSV
 */
export const exportJsonToCsv = (jsonData, fileName, columnDelimiter = ",", lineDelimiter = "\n") => {
  let result, ctr, keys

  if (jsonData === null || !jsonData.length) {
    return null
  }

  keys = Object.keys(jsonData[0]);

  result = ""
  // result += keys.join(columnDelimiter)
  result += keys.map(key => `"${key}"`).join(columnDelimiter)
  result += lineDelimiter

  jsonData.forEach(item => {
    ctr = 0
    keys.forEach(key => {
      if (ctr > 0) {
        result += columnDelimiter
      }

      // result += typeof item[key] === "string" && item[key].includes(columnDelimiter) ? `"${item[key]}"` : item[key]
      result += `"${item[key]}"`
      ctr++
    })
    result += lineDelimiter
  });
  const data = new Blob([result], {type: "text/csv;charset=UTF-8"});
  FileSaver.saveAs(data, fileName + ".csv");
}

export const hardReloadApp = () => {
  if ("serviceWorker" in navigator) {
    navigator.serviceWorker.getRegistrations().then((registrations) => {
      registrations.forEach((registration) => {
        registration.unregister();
      });
    });
  }

  caches.keys().then((keyList) => {
    return Promise.all(
        keyList.map((key) => {
          return caches.delete(key);
        })
    );
  });

  // setTimeout(() => {
    window.location.reload(true);
  // }, 500);
}

export const arrayToObjOfObjs = (arr, keyProperty) => {
  return arr.reduce((acc, obj) => {
    const key = obj[keyProperty];
    acc[key] = obj;
    return acc;
  }, {});
}

// Takes a string as a parameter and returned it in more human and user-friendly readable capitalize string
// Check the "PublicDeliveryAccessFields" component for more understanding of how it works
export function toCapitalizedWords(name) {
  const words = name.match(/[A-Za-z][a-z]*/g) || [];

  return words.map(capitalize).join(" ");
}

export function capitalize(word) {
  return word.charAt(0).toUpperCase() + word.substring(1);
}

export const getNameInitials = (firstName = "", lastName = "") => {
  const firstNameWords = firstName.split(" ");
  const lastNameWords = lastName.split(" ");

  const firstInitial = firstNameWords.map((word) => word.charAt(0).toUpperCase()).join("");
  const lastInitial = lastNameWords.map((word) => word.charAt(0).toUpperCase()).join("");

  return `${firstInitial}${lastInitial}`.substring(0, 3);
}

export const humanReadableDateTimeOptions = {
  month: 'long',
  day: 'numeric',
  year: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
  hour12: false
}

export const isValidUrl = (str) => {
  try {
    new URL(str);
    return true;
  } catch (e) {
    return false;
  }
}
